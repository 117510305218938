import * as React from "react";
import {stepContactData} from "../../data/steps/contact";
import {OrderContext} from "../../context/OrderContext";
import Radiobutton from "../Buttons/Radiobutton";
import {Form} from 'react-bootstrap'
import FooterButtonContainer from "../../components/Buttons/FooterButtonContainer"

const Contact = ({stepBackwards, stepForward, currentStep}) => {

  const {title, subtitle, description, options} = stepContactData;
  const {formValues, handleFormChange, step, setStep,} = React.useContext(OrderContext)
  const [validated, setValidated] = React.useState(false);
  /* ---------------------- Handler para avanzar de form ---------------------- */
  const handleNextForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      stepForward()
      setStep(step + 1)
    }
    event.preventDefault(); setValidated(true);
  }
  return (
    <Form className="step-contact" noValidate validated={validated} onSubmit={handleNextForm}>
      <h1 className="heading-3 mb-4">{title}</h1>
      <div className="form-container">
        <div className="row">
          <div className="col-12">
            <h2 className="fs-20 text-dark-grey text-regular mb-3">{subtitle}</h2>
            <div className="d-flex align-items-center mt-4 mb-5">
              <img src="/headphones.svg" alt="icon" />
              <p className="text-grey text-regular mx-3">{description}</p>
            </div>
            {
              options.map((option,i) => (
                <Radiobutton
                  handleFormChange={handleFormChange} 
                  formValues={formValues}
                  key={i}
                  id={option.id}
                  name={option.name}
                  value={option.value}
                  label={option.label}
                  img={option.img}
                  required={true}
                />
              ))
            }
          </div>
        </div>
        <FooterButtonContainer 
          stepBackwards={stepBackwards} 
          stepForward={stepForward} 
          currentStep={currentStep}
        />
      </div>
    </Form>
  )
}

export default Contact;