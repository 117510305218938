export const stepContactData = {
    title:"¿Cómo querés que te contactemos?",
    subtitle: "Seleccioná la forma de como querés que te contactemos.",
    description: "Horarios de atención: lunes a viernes de 8 a 18 hs. y sábados de 8 a 15 hs.",
    options:[
        {
            value:"whatsapp",
            name:"method_contact",
            label:"Whatsapp",
            img:"/wpp-icon.svg"
        },
        {
            value:"phone",
            name:"method_contact",
            label:"Teléfono",
            img:"/phone-icon.svg"
        },
        {
            value:"email",
            name:"method_contact",
            label:"Email",
            img:"/mail-icon.svg"
        },
    ],
}