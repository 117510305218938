export const stepsValid = [
    {
        id:0,
        label:"Domicilio",
        name:"step 1", 
        // validator: addressValidation,
    },
    {
        id:1,
        label:"Contacto",
        name:"step 2", 
        // validator: contactValidation,
    },
    {
        id:2,
        label:"Datos",
        name:"step 3", 
        // validator: personalInfoValidation,
    },
    {
        id:3,
        label:"Confirmar",
        name:"step 4", 
        // validator: manualConfirmationValidation,
    },

]
export const stepsInvalid = [
    {
        id:0,
        label:"Domicilio",
        name:"step 1", 
        // validator: addressValidation,
    },
    {
        id:1,
        label:"Datos",
        name:"step 2", 
        // validator: personalInfoValidation,
    },
    {
        id:2,
        label:"Confirmar",
        name:"step 3", 
        // validator: manualConfirmationValidation,
    },

]