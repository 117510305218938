import * as React from "react";
import '../../assets/app.scss';
import AppLayout from "../../components/AppLayout";
import {
  withStepProgress,
  useStepProgress,
  Step,
  StepProgressBar
} from "react-stepz";
import "react-stepz/dist/index.css";
import {stepsValid, stepsInvalid} from '../../data/manualRegistrationSteps';
import {stepManualConfirmationData} from '../../data/steps/confirmation';
import Address from '../../components/AppSteps/Address';
import Contact from '../../components/AppSteps/Contact';
import PersonalInfo from '../../components/AppSteps/PersonalInfo';
import Confirmation from '../../components/AppSteps/Confirmation';
import RouteInvalid from "../../components/AppSteps/RouteInvalid";
import { seoManualRegistration } from "../../data/seo";
import Seo from "../../components/seo";
import {Helmet} from 'react-helmet';

const ManualRegistration = () => {
  
  const isBrowser = typeof window !== "undefined"
  const [steps, setSteps] = React.useState([])
  const [order_type, setOrderType] = React.useState("SELLER_ORDER");

  const { stepForward, stepBackwards, currentStep} = useStepProgress({
    steps,
    startingStep: 0,
  });

  // React.useEffect(() => {
  //   if (isBrowser && sessionStorage.getItem('route_available', "true")) {
  //     setSteps(stepsValid)
  //   } else {
  //     setSteps(stepsInvalid)
  //   }
  // },[])
  
  React.useEffect(() => {
     isBrowser && sessionStorage.setItem('order_type', "SELLER_ORDER")
     setOrderType("SELLER_ORDER")
     isBrowser && sessionStorage.setItem('route_available', "true")
     setSteps(stepsValid)
  },[])

  return (
    <AppLayout>
      <div className="manual-registration">

        <Seo
          title={seoManualRegistration.title}
          description={seoManualRegistration.description}
        />
        
        <Helmet
          bodyAttributes={{
            class: 'bk-extra-light-grey'
          }}
        />

        <div className="container-custom">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7">
                <div className="step-progress-bar-container">

                  <div className="step-progress-bar">
                    {isBrowser && sessionStorage.getItem('route_available') === "true" ? (
                    <StepProgressBar
                      steps={stepsValid}
                      stepClass="step-cd"
                    />) : (
                      <StepProgressBar
                      steps={stepsInvalid}
                      stepClass="step-cd"
                    />
                    )}
                  </div>

                  <Step step={0}>
                    <Address
                      stepBackwards={stepBackwards} 
                      stepForward={stepForward} 
                      currentStep={currentStep}
                      orderType={order_type}
                    />
                  </Step>
                  {isBrowser && sessionStorage.getItem('route_available') === "true" ? (
                    <>
                    <Step step={1}>
                      <Contact
                        stepBackwards={stepBackwards} 
                        stepForward={stepForward} 
                        currentStep={currentStep}
                      />
                    </Step>
                    <Step step={2}>
                      <PersonalInfo
                        stepBackwards={stepBackwards} 
                        stepForward={stepForward} 
                        currentStep={currentStep}
                      />
                    </Step>
                    <Step step={3}>
                      <Confirmation
                        customPrevHandler={stepBackwards}
                        confirmationData={stepManualConfirmationData}
                        stepBackwards={stepBackwards} 
                        stepForward={stepForward} 
                        currentStep={currentStep}
                      />
                    </Step>
                  </>
                  ) : (
                  <>
                    <Step step={1}>
                      <PersonalInfo
                        stepBackwards={stepBackwards} 
                        stepForward={stepForward} 
                        currentStep={currentStep}
                      />
                    </Step>
                    <Step step={2}>
                      <RouteInvalid
                        customPrevHandler={stepBackwards}
                        confirmationData={stepManualConfirmationData}
                        stepBackwards={stepBackwards} 
                        stepForward={stepForward} 
                        currentStep={currentStep}
                      />
                    </Step>
                  </>
                  )}
                </div>
              </div>
            </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default withStepProgress(ManualRegistration);
